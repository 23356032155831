body {
  margin: 0;
  padding: 0;
  font-family: "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

p {
  font-size: 16px;
  margin-bottom: 0.2rem;
}


$container-padding: 1rem;

.app-container {
  padding: 0 $container-padding;
}

.titlebar {
  position: sticky;
  top: 0;
  z-index: 1;
  padding-top: $container-padding;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  align-items: flex-end;
  border-bottom: 3px solid darkslategray;
  background-color: white;
  margin-bottom: 2rem;
}

$transition-time: 400ms;
$selector-padding: 0.5rem;
$parent-column-width: 250px;

.step {
  margin-bottom: 2rem;
}

.selector-parent {
  margin-bottom: 2rem;
}

.selector-container {
  display: flex;
  flex-flow: row;
}

.mutation-form {
  flex: 1 1;
  & > input {
    width: 100%;
    box-sizing: border-box;
  }

  &:first-child{
    margin-right: $selector-padding;
  }
}

.mutation-form-submit {
  width: 100%;
}

.wild-type-label {
  color: darkred;
}

.generation-results {
  padding: 0 $container-padding;
  margin-bottom: 2rem;
}

table {
  table-layout: fixed;
  text-align: right;
}

.w {
  width: 350px;
  text-align: left;
}

th,
td {
  padding: 10px;
}